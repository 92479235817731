.leaflet-container {
  width: 100%;
  height: 50vh;
  background-color: transparent;
}

.leaflet-popup-pane a {
  padding: 8px 12px;
  background-color: #257eca;
  border: #35719b;
  border-radius: 4px;
  color: white !important;
  text-decoration: none;
}
.leaflet-popup-pane a:hover {
  background-color: #35719b;
  border-color: #215275;
}
