:root {
  /* --main-bg-color: #c1dd76; */
  --main-bg-color: #a0e84c;
  --primary-color: #22056a;
  --secondary-color: #9fd43c;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body {
  background: var(--main-bg-color)
    url('https://educatiepentrustiinta.ro/images/cest-pattern.2x.png') !important;
  font-family: sans-serif;
  user-select: none;
  cursor: default;
}
a:not([class*="MuiButtonBase"]), a:link:not([class*="MuiButtonBase"]), a:visited:not([class*="MuiButtonBase"]) {
  color:var(--primary-color);
}